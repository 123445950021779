export default {
    common: {
        confirm: '确认',
        cancel: '取消',
        search: '搜索',
        submit: '提交',
        other: '其他',
        default: '默认',
        color_tag: '颜色标签',
        option: '选项',
        success: '成功',
        error: '错误',
        sending: '发送中',
        send_success: '发送成功',
        send_error: '发送失败',
        visits: '访问',
        table: '桌位',
        'one-way-message': '单向短信',
        'two-way-message': '双向短信',
        'one-way-voice': '单向语音',
        'two-way-voice': '双向语音',
        broadcast: '大屏幕广播',
    },
    setting: {
        setting: '设置',
        general: '通用',
        account: '账户',
        about: '关于',
        custom_language_label: '自定义语言',
        logo_label: '餐厅标志',
        name_label: '餐厅名称',
        version_label: '版本',
        privacy_label: '隐私声明',
        terms_label: '服务条款',
        logout_btn: '退出登录',
        logout_tip: '退出当前登录吗？'
    },
    waitlist: {
        waitlist: '等位',
        all: '全部',
        no_show: '过号',
        check_in: '签到',
        checked_in: '准备入座',
        waiting: '等待中',
        notify: '通知',
        seat: '入座',
        add_waitlist: '添加排队',
        guest_number: '客人数量',
        guest_name: '客人姓名',
        phone_num: '电话号码',
        visit_note: '备注',
        onsite_notify: '现场通知',
        table_ready: '就餐准备',
        broadcast: '大屏幕广播',
        two_way_sms: '双向短信',
        two_way_voice: '双向语音',
        resend_invite: '重新邀请',
        seat_with_table: '带餐桌入座',
        waitlist_detail: '排队详情',
        table_assign: '关联真实餐桌',
        timeline: '时间线',
        not_arrived: '未到',
        partial_arrived: '部分到达',
        all_arrived: '全部到达',
        join_waitlist: '加入排队',
        input_name_tip: '请输入客人姓名',
        choose_people_num_tip: '请选择客人数量',
        restore: '过号恢复',
        restore_top: '恢复到原位置',
    },
    reserve: {
        reserve: '预约',
        notify_one_way_remind: '预约单向提醒',
        notify_sms: '短信',
        notify_voice: '语音',
        cancel: '取消预约',
        reserve_detail: '预约详情',
        create_reservation: '创建预约',
        merchant_remark: '餐厅备注',
    },
    floorplan: {
        floorplan: '平面图'
    },
};